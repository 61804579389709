







































































import { Component, Vue, Prop } from 'vue-property-decorator'

import TableRow from '@/components/table/Row.vue'
import Modal from '@/components/modals/Default.vue'
import Dots from '@/components/widgets/DotDotDot.vue'
import CreateEntity from './partials/CreateEntity.vue'
import UpdateEntity from './partials/UpdateEntity.vue'
import TableWrapper from '@/components/table/Wrapper.vue'
import TableHeading from '@/components/table/Heading.vue'
import Pagination from '@/components/paginations/Pagination.vue'

import { EntityResource } from '@/models/implementations/entities/EntityResource'
import { ImplementationResource } from '@/models/implementations/ImplementationResource'
import { EntityCollectionResource } from '@/models/implementations/entities/EntityCollectionResource'

@Component({
  components: {
    Dots,
    Modal,
    TableRow,
    Pagination,
    CreateEntity,
    UpdateEntity,
    TableHeading,
    TableWrapper,
  }
})
export default class ImplementationDetailScope extends Vue {

  @Prop()
  private implementation!: ImplementationResource

  private index: IndexResponse<EntityCollectionResource | EntityResource> | null = null
  private isFetchingData: boolean = false
  private params: IndexParameters = {
    column: 'name',
    dir: 'asc'
  }

  private isDeletingEntity: number | null = null

  private isCreatingEntityModalOpen: boolean = false
  private isUpdatingEntityModalOpen: boolean = false

  private updatingEntity: EntityCollectionResource | null = null

  private get hasData(): boolean {
    return this.index ? this.index.data.length > 0 : false
  }

  private get canCreateEntity(): boolean {
    return this.implementation.canPerformAction('can_create_entity')
  }

  private get canDeleteEntity(): boolean {
    return this.implementation.canPerformAction('can_change_entity')
  }

  private created(): void {
    this.getData()
  }

  private async getData(): Promise<void> {
    try {
      this.isFetchingData = true
      const data = await this.implementation.getEntities(this.params)
      this.index = data
    } finally {
      this.isFetchingData = false
    }
  }

  private goToPage(page: string) {
    this.params.page = page
    this.getData()
  }

  private async deleteEntity(entity: EntityResource): Promise<void> {
    try {
      this.isDeletingEntity = entity.id
      await this.implementation.deleteEntity(entity.id)
      await this.getData()
      this.implementation.refresh()
    } finally {
      this.isDeletingEntity = null
    }
  }

  private entityCreated(data: EntityResource): void {
    this.isCreatingEntityModalOpen = false
    this.getData()
    this.implementation.refresh()
  }

  private openCreateEntityModal(): void {
    this.isCreatingEntityModalOpen = true
  }

  private closeCreateEntityModal(): void {
    this.isCreatingEntityModalOpen = false
  }

  private openUpdateEntityModal(entity: EntityCollectionResource): void {
    this.updatingEntity = entity
    this.isUpdatingEntityModalOpen = true
  }

  private closeUpdateEntityModal(): void {
    this.updatingEntity = null
    this.isUpdatingEntityModalOpen = false
  }

  private entityUpdated(data: EntityResource): void {
    this.isUpdatingEntityModalOpen = false
    this.implementation.refresh()
  }

  private setOrder(col: string, dir: string): void {
    this.params.column = col
    this.params.dir = dir
    this.getData()
  }

  private getRowElements(document: EntityCollectionResource): TableDataElement[] {
    const data: TableDataElement[] = []
    if (this.index && this.index.elements) {
      this.index.elements.forEach((element: TableElement) => {
        const KEY = element.key as keyof EntityCollectionResource
        const ELEMENT_VALUE = document[KEY]
        let element_value: EntityCollectionResource[keyof EntityCollectionResource] = '-'
        if (typeof ELEMENT_VALUE === 'string' || typeof ELEMENT_VALUE === 'number' || typeof ELEMENT_VALUE === 'boolean') {
          element_value = ELEMENT_VALUE
        } else if (Array.isArray(ELEMENT_VALUE)) {
          element_value = ELEMENT_VALUE.length > 0 ? ELEMENT_VALUE.join(', ') : '-'
        }
        data.push({
          value: element_value,
          type: element.type,
          width: element.width,
        })
      })
    }
    return data
  }

}
