
































import { Component, Vue, Prop } from 'vue-property-decorator'

import TextInput from '@/components/inputs/Text.vue'
import UserImage from '@/components/user/UserImage.vue'
import SearchInput from '@/components/inputs/Search.vue'
import SmallLoader from '@/components/loaders/SmallLoader.vue'

import { UserResource } from '@/models/users/UserResource'
import { ImplementationResource } from '@/models/implementations/ImplementationResource'
import { EntityCreateRequest } from '@/requests/implementations/entities/EntityCreateRequest'

@Component({
  components: {
    UserImage,
    TextInput,
    SmallLoader,
    SearchInput
  }
})
export default class CreateEntity extends Vue {

  @Prop()
  private implementation!: ImplementationResource

  private isFetchingData: boolean = false
  private isSubmitting: boolean = false
  private availableUsers: UserResource[] = []
  private search: string = ''

  private form: EntityCreateRequest = new EntityCreateRequest()
  private errors: ErrorResponse = {}

  private get canSubmitForm(): boolean {
    return this.form.isValid
  }

  private get filteredUsers(): UserResource[] {
    return this.availableUsers.filter((user) => user.name.toLowerCase().includes(this.search.toLowerCase()))
  }

  private get hasData(): boolean {
    return this.availableUsers.length > 0
  }

  private get hasFilteredUsers(): boolean {
    return this.availableUsers.length > 0
  }

  private created(): void {
    this.getAvailableUsers()
  }

  private async getAvailableUsers(): Promise<void> {
    this.isFetchingData = true
    try {
      const { data } = await this.implementation.getAvailableEntityUsers()
      this.availableUsers = data
    } catch (e) {
      if (e.errors) {
        this.errors = e.errors
      }
    } finally {
      this.isFetchingData = false
    }
  }

  private toggleUser(user: UserResource): void {
    this.form.lead_id = this.form.lead_id === user.id ? null : user.id
  }

  private async submit(): Promise<void> {
    this.isSubmitting = true
    try {
      const data = await this.implementation.createEntity(this.form)
      this.$emit('submit', data)
    } finally {
      this.isSubmitting = false
    }
  }

  private close(): void {
    this.$emit('close')
  }

}
